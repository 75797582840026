<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action" v-if="isChanged">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Өөрчлөлт хадгалах</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
          <el-button type="success" @click="onPressSave('productForm')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header title="Бүтээгдэхүүн нэмэх" />
        <!-- form error template -->
        <div class="form-error-container mb20" id="error-listener" ref="errorListener" v-if="errorChecker.length != 0">
          <header>Уучлаарай дутуу {{errorChecker.length}} зүйл байна</header>
          <ul class="error-list">
            <div v-for="(file, index) in errorChecker" :key="index">
              <li>{{file}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="productForm" :rules="productRules" ref="productForm" @submit.native.prevent="onPressSave('productForm')">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="name_mon" >
                        <el-input v-model="productForm.name_mon" placeholder="Говь ноолууран цамц"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Англи нэр" prop="name_eng" >
                        <el-input v-model="productForm.name_eng" placeholder="Gobi sweater"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label="Бүртгэлийн нэр">
                    <el-input v-model="productForm.register_name" placeholder="Барааны бүртгэлийн нэр"></el-input>
                  </el-form-item>
                  <el-form-item label="Монгол тайлбар" prop="description_mon">
                    <vue-editor v-model="productForm.description_mon"></vue-editor>
                  </el-form-item>
                  <el-form-item label="Англи тайлбар" prop="description_eng">
                    <vue-editor v-model="productForm.description_eng"></vue-editor>
                  </el-form-item>
                  <!-- <el-form-item label="Категори" prop="category_id" multiple class="position-relative">
                    <tree-selector v-model="productForm.category_id" :options="categories" :props="defaultProps" />
                  </el-form-item> -->
                  <el-form-item label="Категори" prop="category_id" multiple class="position-relative">
                    <tree-selector v-model="productForm.category_id" :treeData="categories" multiple width="100%" />
                  </el-form-item>
                </div>
              </div>
              <!-- Media section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Зураг /бүтээгдэхүүн/</header>
                  <el-upload
                    class="mt5"
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :file-list="tempImages"
                    :auto-upload="false"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="(file) => handleRemove(file, 'image')"
                    :on-change="(file) => getFile(file, 'image')">
                    <span>1020x1020</span>
                  </el-upload>
                  <loader :active="isUploading" />
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Зураг /сав, баглаа/</header>
                  <el-upload
                    class="mt5"
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :file-list="tempImagesPackage"
                    :auto-upload="false"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="(file) => handleRemove(file, 'package')"
                    :on-change="(file) => getFile(file, 'package')">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <loader :active="isUploadingPackage" />
                </div>
              </div>
              <!-- Price section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Үнэ</header>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Татах үнэ" prop="get_price">
                        <el-input v-model="productForm.get_price" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Борлуулах үнэ" prop="price">
                        <el-input v-model="productForm.price" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <div class="pb20">
                    <el-checkbox v-model="productForm.sell_check">Энэ хямдралтай бүтээгдэхүүн</el-checkbox>
                  </div>
                  <div class="variants-container pane" v-if="productForm.sell_check">
                    <el-form-item label="Хямдарсан үнэ">
                      <el-input v-model="productForm.discounted_price" type="number" placeholder="0₮">
                        <template slot="append">₮</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <!-- Group order section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Групп захиалга</header>
                </div>
                <div class="panel-item">
                  <strong>24</strong> цагийн дотор <strong>10</strong> хүн <strong>20%</strong> хямдралтай авах боломжтой
                </div>
              </div>
              <!-- Variants section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Бараа сонголт</header>
                  <div class="pb20">
                    <el-checkbox v-model="productForm.haveVariant">Энэ бүтээгдэхүүн олон сонголттой бараа</el-checkbox>
                  </div>
                </div>
                <div class="variants-container pane" v-if="productForm.haveVariant">
                  <div class="panel-item" v-for="(variant, index) in variantNames" :key="index">
                    <el-form-item :label="'Сонголт ' + (index + 1)">
                      <el-row :gutter="5">
                        <el-col :span="8">
                          <el-select
                            v-model="variant.name"
                            filterable
                            allow-create
                            default-first-option
                            @change="changeVariantSelect"
                            placeholder="Сонголт">
                            <el-option
                              v-for="item in options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="12">
                          <el-select
                            class="variant-options-select"
                            v-model="variant.value"
                            multiple
                            filterable
                            allow-create
                            default-first-option
                            @change="changeVariant"
                            placeholder="Жиш : ноолуур, ноосон гэх мэт">
                            <el-option
                              v-for="item in optionValues"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                            </el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="4">
                          <el-button type="default" @click="removeVariant(index)"><i class="el-icon-delete-solid"></i></el-button>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </div>
                  <div v-if = "variantNames.length <3" class="panel-item" style="border-bottom: 1px solid #dfe3e8; padding-bottom: 20px">
                    <el-button type="default" @click="addVariant()">Сонголт нэмэх <i class="el-icon-plus"></i></el-button>
                  </div>
                  <div class="panel-item" v-if="productForm.variants.length > 0">
                    <header>Урьдчилан харах<span> - {{productForm.variants.length}} төрөл</span></header>
                    <el-table
                      :data="productForm.variants"
                      style="width: 100%">
                      <el-table-column
                        label="Нэр"
                        :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                          {{scope.row.variant_name}}
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="150px"
                        label="Доод үлдэгдэл">
                        <template slot-scope="scope">
                          <el-input type="number" v-model="scope.row.threshold_number" :min="0"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column align="center">
                        <template slot-scope="scope">
                          <el-upload
                            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                            :auto-upload="false"
                            action=""
                            :on-remove="(file) => handleRemove(file, 'variant')"
                            :on-change="(file) => getFileVariant(file, scope.row.variant_name)">
                            <el-button  type="primary">Зураг</el-button>
                          </el-upload>
                          <loader :active="isUploadingVariant" />
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
              <!-- Inventory section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Агуулах</header>
                  <!-- <el-form-item label="Barcode (ISBN, UPC, GTIN, etc.)" prop="barcode">
                    <el-input v-model="productForm.barcode" type="text"></el-input>
                  </el-form-item> -->
                </div>
                <div class="panel-item">
                  <div class="mb20">
                    <el-checkbox v-model="productForm.is_physical">Энэ нь биетэй бүтээгдэхүүн</el-checkbox>
                  </div>
                </div>
                <div class="panel-item" v-if="productForm.is_physical">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        label="Жин">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Weight" type="number">
                              <el-select v-model="productSize.weightSuffix" slot="append">
                                <el-option label="kg" value="kg"></el-option>
                                <el-option label="gram" value="g"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Урт">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Length" type="number">
                              <el-select v-model="productSize.lengthSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        label="Өргөн">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Width" type="number">
                              <el-select v-model="productSize.widthSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Өндөр">
                        <el-input v-model="productSize.Height" type="number" class="input-with-select">
                          <template slot="append">
                            <el-select v-model="productSize.heightSuffix" placeholder="сонгох">
                              <el-option label="cm" value="cm"></el-option>
                              <el-option label="inch" value="inch"></el-option>
                            </el-select>
                          </template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
            <el-col :span="8">
              <!-- Product status section -->
              <div class="panel">
                <div class="panel-item">
                  <el-form-item label="Төлөв" prop="status">
                    <el-select v-model="productForm.status" class="block">
                      <el-option
                        v-for="item in statusOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- Brand section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Нийлүүлэгч & Брэнд</header>
                  <el-form-item label="Нийлүүлэгч" prop="supplier_id">
                    <el-select
                      v-model="productForm.supplier_id"
                      @change="onChangeSupplier"
                      filterable
                      remote
                      placeholder="Таван богд"
                      :remote-method="filterSupplier"
                      :loading="supplierLoading">
                      <el-option
                        v-for="item in suggestedSupplier"
                        :key="item.supplier_id"
                        :label="item.supplier_monName"
                        :value="item.supplier_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Брэнд" prop="brand_id" v-if="productForm.supplier_id != ''">
                    <loader :active="isLoadBrand" />
                    <el-select
                      v-model="productForm.brand_id"
                      filterable
                      class="block">
                      <el-option
                        v-for="item in brandOptions"
                        :key="item.brand_id"
                        :label="item.brand_monName"
                        :value="item.brand_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- Properties section -->
              <div class="panel">
                <div class="panel-item pb20">
                  <header>Шинж чанар</header>
                  <el-form-item label="Нэр">
                    <el-select
                      v-model="property.name"
                      filterable
                      @change="onChangeProperty"
                      allow-create
                      class="block">
                      <el-option
                        v-for="(item, index) in propertiesName"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                   <el-form-item label="Утга">
                    <el-select
                      v-model="property.value"
                      filterable
                      allow-create
                      class="block">
                      <el-option
                        v-for="(item, index) in propertiesValue"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="default" class="block" @click="addProperty">Шинж нэмэх</el-button>
                  </el-form-item>
                </div>
                <div class="panel-item" v-for="(property, index) in productForm.properties" :key="index">
                  <div class="property-item">
                    <el-row :gutter="10" type="flex" align="middle">
                      <el-col :span="18">
                        <div>
                          <strong>{{property.name}}</strong>
                        </div>
                        <div>
                          <span class="text-color-secondary">{{property.value}}</span>
                        </div>
                      </el-col>
                      <el-col :span="6" class="text-right">
                        <i class="el-icon-delete cursor-pointer" @click="removeProperty(property)"></i>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="text-right border-top pt20">
            <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
            <el-button type="success" @click="onPressSave('productForm')">Хадгалах</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Өөрчлөлтүүдийг хадгалах уу"
      :visible.sync="confirmLeaveForm"
      width="50%">
      <span>Хэрвээ та устгах товч дарвал таны хийсэн бүх өөрчлөлт устагдахыг анхаарна уу.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10">Үргэлжлүүлэх</el-button>
        <el-button type="danger" @click="onPressClose">Устгах</el-button>
      </span>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
import services from '../../../helpers/services'
import { handlers } from '../../../helpers/custom'
import CustomHeader from '../../../components/common/customHeader'
import TreeSelector from '../../../components/common/treeSelector'
import Loader from '../../../components/common/loader'

export default {
  name: 'addProduct',
  components: {
    CustomHeader,
    TreeSelector,
    Loader,
    VueEditor
  },
  data () {
    return {
      property: {
        name: '',
        value: ''
      },
      defaultProps: {
        parent: 'parent',
        value: 'uniq_id',
        label: 'name_mon',
        children: 'childrens'
      },
      isLoading: false,
      isUploading: false,
      isUploadingPackage: false,
      isUploadingVariant: false,
      confirmLeaveForm: false,
      dialogImageUrl: '',
      isNewForm: true,
      dialogVisible: false,
      categories: [],
      variantNames: [],
      tempImages: [],
      tempImagesPackage: [],
      options: [],
      optionValues: [],
      statusOption: [],
      isLoadBrand: false,
      brandOptions: [],
      suggestedSupplier: [],
      propertiesValue: [],
      propertiesName: [],
      supplierLoading: false,
      productSize: {
        Weight: '',
        Length: '',
        Width: '',
        Height: '',
        weightSuffix: 'kg',
        heightSuffix: 'cm',
        widthSuffix: 'cm',
        lengthSuffix: 'cm'
      },
      productForm: {
        name_mon: '',
        name_eng: '',
        register_name: '',
        description_mon: '',
        description_eng: '',
        price: '',
        get_price: '',
        sell_check: false,
        discounted_price: '',
        barcode: '',
        haveVariant: true,
        variant_check: true,
        image: [],
        package_image: [],
        is_physical: true,
        supplier_id: '',
        brand_id: '',
        status: '',
        category_id: [],
        variants: [],
        properties: [],
        physical_size: {
          Weight: '',
          Length: '',
          Width: '',
          Height: ''
        }
      },
      productRules: {
        name_mon: [
          { required: true, message: 'Монгол нэрээ оруулна уу', trigger: 'change' }
        ],
        name_eng: [
          { required: true, message: 'Англи нэрээ оруулна уу', trigger: 'change' }
        ],
        description_mon: [
          { required: true, message: 'Монгол тайлбараа оруулна уу', trigger: 'change' }
        ],
        description_eng: [
          { required: true, message: 'Англи тайлбараа оруулна уу', trigger: 'change' }
        ],
        category_id: [
          { required: true, message: 'Категорио сонгоно уу', trigger: 'change' }
        ],
        price: [
          { required: true, message: 'Үнээ оруулна уу', trigger: 'change' }
        ],
        // barcode: [
        //   { required: true, message: 'Баркод оруулна уу', trigger: 'change' }
        // ],
        get_price: [
          { required: true, message: 'Татах үнээ оруулна уу', trigger: 'change' }
        ],
        status: [
          { required: true, message: 'Төлөвөө сонгоно уу', trigger: 'change' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'change' }
        ],
        brand_id: [
          { required: true, message: 'Брэндээ сонгоно уу', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {
    this.getCategory()
    this.getStatusOptions()
    this.getVariantName([])
    this.getPropertiesName([])
  },
  computed: {
    isChanged () {
      const defaultData = {
        name_mon: '',
        name_eng: '',
        register_name: '',
        description_mon: '',
        description_eng: '',
        price: '',
        get_price: '',
        sell_check: false,
        discounted_price: '',
        barcode: '',
        haveVariant: true,
        variant_check: true,
        image: [],
        package_image: [],
        is_physical: true,
        brand_id: '',
        status: 'active',
        category_id: '',
        variants: [],
        properties: [],
        physical_size: {
          Weight: '',
          Length: '',
          Width: '',
          Height: ''
        }
      }
      return JSON.stringify(this.productForm) !== JSON.stringify(defaultData)
    },
    errorChecker () {
      var errors = []
      if (!this.isNewForm) {
        if (this.tempImages.length === 0) {
          errors.push('Зураг оруулна уу')
        }
        if (this.productForm.properties.length === 0) {
          errors.push('Шинж чанар оруулна уу')
        }
        if (this.productForm.haveVariant && this.productForm.variants.length === 0) {
          errors.push('Бүтээгдэхүүн сонголт оруулна уу')
        }
        if (this.productForm.sell_check && this.productForm.discounted_price === '') {
          errors.push('Бүтээгдэхүүний хямдарсан үнээ оруулна уу')
        }
      }
      return errors
    },
    categoryId () {
      return this.productForm.category_id
    }
  },
  watch: {
    categoryId (newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue) || newValue.length === 0) {
        this.getVariantName(newValue)
        this.getPropertiesName(newValue)
      }
    }
  },
  methods: {
    onChangeProperty (value) {
      const tempOptions = []
      services.getPropertiesValue(value).then(response => {
        if (response.status === 'success' && response.data && response.data.length > 0) {
          response.data.forEach(element => {
            tempOptions.push({
              value: element.properties_value,
              label: element.properties_value
            })
          })
        }
      })
      this.propertiesValue = tempOptions
    },
    getPropertiesName (category) {
      const body = {
        is_category: category.length > 0,
        categories_id: category
      }
      const tempOptions = []
      services.getPropertiesName(body).then(response => {
        if (response.status === 'success' && response.data && response.data.length > 0) {
          response.data.forEach(element => {
            tempOptions.push({
              value: element.properties_name,
              label: element.properties_name
            })
          })
        }
      })
      this.propertiesName = tempOptions
    },
    getVariantName (category) {
      const body = {
        is_category: category.length > 0,
        categories_id: category
      }
      services.getOptionName(body).then(response => {
        let tempOptions = []
        if (response.status === 'success' && response.data && response.data.length > 0) {
          response.data.forEach((element, index) => {
            tempOptions.push({
              value: element,
              label: element
            })
          })
        } else {
          tempOptions = [{
            value: 'Хэмжээ',
            label: 'Хэмжээ'
          }, {
            value: 'Өнгө',
            label: 'Өнгө'
          }, {
            value: 'Материал',
            label: 'Материал'
          }, {
            value: 'Загвар',
            label: 'Загвар'
          }, {
            value: 'Гарчиг',
            label: 'Гарчиг'
          }]
        }
        let isReady = true
        this.variantNames.forEach(element => {
          if (element.value.length !== 0) {
            isReady = false
          }
        })
        if (isReady) {
          this.variantNames = [{
            name: tempOptions[0].value,
            value: []
          }]
          this.changeVariantSelect(tempOptions[0].value)
        }
        this.options = tempOptions
      })
    },
    filterSupplier (val) {
      if (val.length > 1) {
        services.searchSupplier(val).then(response => {
          if (response.datas && Array.isArray(response.datas)) {
            this.suggestedSupplier = response.datas
          } else {
            this.suggestedSupplier = []
          }
        })
      }
    },
    handleRemove (file, type, fileList) {
      var tempId = 0
      if (type === 'image') {
        this.tempImages.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImages.splice(tempId, 1)
      } else if (type === 'package') {
        this.tempImagesPackage.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesPackage.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    getFile (file, type) {
      handlers.checkImageSize(file, type === 'image', 1020, 1020).then(() => {
        if (type === 'image') {
          this.isUploading = true
        } else if (type === 'package') {
          this.isUploadingPackage = true
        }
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (type === 'image') {
              this.isUploading = false
            } else if (type === 'package') {
              this.isUploadingPackage = false
            }
            if (response.status === 'success') {
              if (type === 'image') {
                this.tempImages.push({
                  url: response.url
                })
              } else if (type === 'package') {
                this.tempImagesPackage.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    getFileVariant (file, name) {
      this.isUploadingVariant = true
      handlers.getBase64(file.raw).then(res => {
        const tempBody = {
          image: res,
          filePath: file.name
        }
        services.imageUpload(tempBody).then(response => {
          this.isUploadingVariant = false
          if (response.status === 'success') {
            var tempData = JSON.parse(JSON.stringify(this.productForm.variants))
            tempData.forEach((element) => {
              if (element.variant_name === name) {
                var tempImages = tempData.image ? tempData.image : []
                tempImages.push(response.url)
                element.variant_image = tempImages
              }
            })
            this.productForm.variants = tempData
          } else {
            this.$message.error('Уучлаарай алдаа гарлаа!')
          }
        })
      })
    },

    getCategory () {
      services.getCategoriesAsTree().then(data => {
        data.forEach(element => {
          element.label = element.name_mon
          element.id = element.uniq_id
          if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
            element.childrens.forEach(el => {
              el.label = el.name_mon
              el.id = el.uniq_id
              if (Object.prototype.hasOwnProperty.call(el, 'childrens')) {
                el.childrens.forEach(el1 => {
                  el1.label = el1.name_mon
                  el1.id = el1.uniq_id
                  if (Object.prototype.hasOwnProperty.call(el1, 'childrens')) {
                    el1.childrens.forEach(el2 => {
                      el2.label = el2.name_mon
                      el2.id = el2.uniq_id
                    })
                    el1.children = el1.childrens
                  }
                })
                el.children = el.childrens
              }
            })
            element.children = element.childrens
          }
        })
        this.categories = data
      })
    },
    getStatusOptions () {
      const options = [{
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }]
      this.statusOption = options
      this.productForm.status = options[0].value
    },
    onChangeSupplier (id) {
      this.isLoadBrand = true
      services.getBrandsBySupplierId(id).then(response => {
        this.isLoadBrand = false
        if (response.status === 'success') {
          this.brandOptions = response.data.datas
          this.productForm.supplier_id = id
          this.productForm.brand_id = ''
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    onPressClose () {
      this.confirmLeaveForm = false
      this.$router.go(-1)
    },
    addSuffix (data) {
      var subData = {}
      subData.Weight = `${data.Weight} ${data.weightSuffix}`
      subData.Height = `${data.Height} ${data.heightSuffix}`
      subData.Width = `${data.Width} ${data.widthSuffix}`
      subData.Length = `${data.Length} ${data.lengthSuffix}`
      return subData
    },
    onPressSave (formName) {
      this.isNewForm = false
      this.productForm.physical_size = this.addSuffix(this.productSize)
      this.productForm.price = parseFloat(this.productForm.price)
      this.productForm.get_price = parseFloat(this.productForm.get_price)
      this.productForm.discounted_price = this.productForm.discounted_price !== '' ? parseFloat(this.productForm.discounted_price) : 0
      var tempImages = []
      this.tempImages.forEach(element => {
        tempImages.push(element.url)
      })
      this.productForm.image = tempImages
      var tempImagesPackage = []
      this.tempImagesPackage.forEach(element => {
        tempImagesPackage.push(element.url)
      })
      this.productForm.package_image = tempImagesPackage
      this.$refs[formName].validate((valid) => {
        if (valid && this.errorChecker.length === 0 && !this.isUploading && !this.isUploadingPackage) {
          this.isLoading = true
          services.addProduct(this.productForm).then(data => {
            this.isLoading = false
            this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
            if (data.status === 'success') {
              this.$router.go(-1)
            }
          })
        } else {
          window.scrollTo(0, 0)
          return false
        }
      })
    },
    removeProperty (property) {
      this.productForm.properties = this.productForm.properties.filter(item => property !== item)
    },
    addProperty (e) {
      if (this.property.name && this.property.value) {
        this.productForm.properties = [...this.productForm.properties, { name: this.property.name, value: this.property.value }]
        this.property.name = ''
        this.property.value = ''
      }
    },
    variantType (name, option) {
      return {
        variant_name: name,
        option: option
      }
    },
    changeVariant () {
      var tempVariants = []
      if (this.variantNames.length === 1) {
        this.variantNames[0].value.forEach(el => {
          tempVariants.push(
            this.variantType(
              el,
              [{
                name: this.variantNames[0].name,
                value: el
              }]
            )
          )
        })
      } else if (this.variantNames.length === 2) {
        this.variantNames[0].value.forEach(el => {
          this.variantNames[1].value.forEach(el1 => {
            tempVariants.push(
              this.variantType(
                el + '/' + el1,
                [{
                  name: this.variantNames[0].name,
                  value: el
                }, {
                  name: this.variantNames[1].name,
                  value: el1
                }]
              )
            )
          })
        })
      } else if (this.variantNames.length === 3) {
        this.variantNames[0].value.forEach(el => {
          this.variantNames[1].value.forEach(el1 => {
            this.variantNames[2].value.forEach(el2 => {
              tempVariants.push(
                this.variantType(
                  el + '/' + el1 + '/' + el2,
                  [{
                    name: this.variantNames[0].name,
                    value: el
                  }, {
                    name: this.variantNames[1].name,
                    value: el1
                  }, {
                    name: this.variantNames[2].name,
                    value: el2
                  }]
                )
              )
            })
          })
        })
      }
      this.productForm.variants = tempVariants
    },
    addVariant () {
      var unused = null
      this.options.forEach(element => {
        if (!unused) {
          unused = element.value
        }
      })
      this.variantNames.push(
        {
          name: unused,
          value: []
        }
      )
      this.changeVariant()
    },
    changeVariantSelect (value) {
      const body = {
        option_name: value,
        categories_id: this.productForm.category_id
      }
      services.getOptionValue(body).then(response => {
        if (response.status === 'success' && response.data && response.data.length > 0) {
          const tempValues = []
          response.data.forEach(element => {
            if (element && element.name_mon && element.hex_code) {
              tempValues.push({
                value: element.name_mon,
                code: element.hex_code,
                label: element.name_mon
              })
            } else {
              tempValues.push({
                value: element,
                label: element
              })
            }
          })
          this.optionValues = tempValues
        }
      })
    },
    removeVariant (index) {
      this.variantNames.splice(index, 1)
      this.changeVariant()
    }
  }
}
</script>
